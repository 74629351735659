import { useRef, useEffect } from 'react'
import data from '../data/main.json'
import Description from '../components/Description'
import ProfilePhoto from '../components/Photo'

export default function Main () {
    const boxRef = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            boxRef.current.classList.add('is-active')
        }, 250)
    })

    return (<div className="center-box" ref={boxRef}>
        <div className="level">
            <div className="level-left">
                <div className="level-item"><Description {...data} /></div>
            </div>
            <div className="level-right">
                <div className="level-item"><ProfilePhoto /></div>
            </div>
        </div>
    </div>)
}
