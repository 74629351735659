import { Routes, Route } from 'react-router-dom'
import Hero from './Hero'
import Main from './pages/Main'

export default function App() {
  return (
      <>
        <Hero />
        <Routes>
            <Route path="/" element={<Main />} />
        </Routes>
      </>
  )
}
