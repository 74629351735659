import Link from './ExternalLink'

export default function Description ({ description, name, title, links, linkedin }) {
    return (<div className="description has-text-left">
        <h1 className="title is-spaced has-text-primary has-text-centered-mobile">{name}</h1>
        <h2 className="subtitle is-6 is-uppercase has-text-primary-dark has-text-centered-mobile">{title}</h2>
        <div className="divider my-5" />
        <p className="mb-5 has-text-white">{description}</p>
        <a
            className="button is-rounded is-primary is-large is-fullwidth my-4 linkedin-link"
            href={linkedin}
            target="_blank"
        >
            <span className="is-size-6 is-uppercase">Get in touch</span>
        </a>
        <div className="has-text-centered has-text-white">
            {links.map((x, index) =>
                <Link
                    key={`link-${index}`}
                    {...x}
                    isLast={index === links.length - 1}
                />
            )}
        </div>
    </div>)
}