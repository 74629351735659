import React from 'react'
import ReactDOM from 'react-dom'
import './style.sass'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import mixpanel from 'mixpanel-browser'

mixpanel.init('257275697ff48b88de09f61f19f3d5c1', {
    debug: process.env.NODE_ENV !== 'production',
})

mixpanel.track_links('.description a.linkedin-link', "Went to LinkedIn")
mixpanel.track_links('.description a.external-link', "Went to check other links")


ReactDOM.render(
  <React.StrictMode>
      <HelmetProvider>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
