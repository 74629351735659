export default function ExternalLink ({ link, name, isLast }) {
    return (<>
        <a
            rel="noreferrer"
            target="_blank"
            className="has-text-white external-link"
            href={link}
        >
            {name}
        </a>
        {!isLast ? <span className="mx-3">•</span> : null}
    </>)
}